<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col>
        <DBModel_CardTable :listModel="dateiModel"/>
      </v-col>
      <v-col>
        <DBModel_CardTable :listModel="linkModel"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DBModel_CardTable from "@/components/views/DBModel_CardTable.vue";

export default {
  name: "RefInformation",
  components: {DBModel_CardTable},
  data() {
    return {
      name: "Informationen",
      backend: process.env.VUE_APP_STRAPI_API_URL,

      dateiModel: {
        titel:'Datei Sammlung',
        type:'uploads',
        endpoint:'/ref-informationen-datei-uploads',
        neuerEintrag : {
          initBtn: {
            titel: 'neue Datei Hochladen',
            btnColor: 'green',
            label:'upload',

          },
          titel:'Datei Upload',
          speichernBtnTitle:'Datei Hochladen',
          abbrechenBtnTitle:'Abbrechen',
          roles:['AppAdmin'],
        },
        eintragLoeschen:{
          roles:['AppAdmin'],
        },
        eintraegeNeuOrdnen:{
          roles:['AppAdmin'],
        },
        model: {

          headers: [
            {
              text: 'Actions',
              value: 'actions',
              sortable: false
            },
            // {
            //   text: 'position',
            //   align: 'start',
            //   sortable: true,
            //   value: 'position',
            //   type:'zahl'
            // },
            {
              text: 'hochgeladen am',
              align: 'start',
              sortable: false,
              value: 'datei.createdAt',
              type:'datum'
            },
            // {
            //   text: 'hochgeladen von',
            //   align: 'start',
            //   sortable: false,
            //   value: 'datei.hochgeladen_von',
            //   type:'text'
            // },
            {
              text: 'Datei',
              align: 'start',
              sortable: false,
              value: 'datei.name',
              type:'datei'
            },
            {
              text: '',
              value: 'moving',
              sortable: false
            },

          ]
        }
      },

      linkModel: {
        titel:'Link Sammlung',
        type:'url',
        endpoint:'/ref-informationen-links',
        neuerEintrag : {
          initBtn: {
            titel: 'neuen Link Erstellen',
            btnColor: 'green',
          },
          titel:'Link Erstellen',
          speichernBtnTitle:'Link Erstellen',
          abbrechenBtnTitle:'Abbrechen',
          roles:['AppAdmin'],
        },
        eintragLoeschen:{
          roles:['AppAdmin'],
        },
        eintraegeNeuOrdnen:{
          roles:['AppAdmin'],
        },

        model: {
          headers: [
            {
              text: 'Actions',
              value: 'actions',
              sortable: false
            },

            // {
            //   text: 'gewicht',
            //   align: 'start',
            //   sortable: false,
            //   value: 'position',
            //   type:'zahl'
            // },
            {
              text: 'hochgeladen am',
              align: 'start',
              sortable: false,
              value: 'createdAt',
              type:'datum'
            },

            {
              text: 'Link',
              align: 'start',
              sortable: false,
              value: 'url',
              type:'url'
            },
            {
              text: '',
              value: 'moving',
              sortable: false
            },
          ]
        }
      }
    };
  },
  created() {
    this.userRole = localStorage.getItem('role') ;
  },
  watch: {

  },
  computed: {

  },
  method: {

  }
};
</script>
