
<style scoped>

</style>


<template>
  <v-card class="mx-auto">
    <v-card-title>
    <v-row>
  <v-col>
    {{listModel.titel}}

  </v-col>
<v-col>

  <v-btn v-if="listModel.neuerEintrag && listModel.neuerEintrag.roles && listModel.neuerEintrag.roles.indexOf(userRole) !== -1" class="float-right" rounded elevation="2" @click="neuerEintragShow = true" :color="listModel.neuerEintrag.initBtn.btnColor" >{{ listModel.neuerEintrag.initBtn.titel }}</v-btn>
</v-col>
    </v-row>

    </v-card-title>
<!--    DAta Table-->
    <v-data-table :items="tabelData" :headers="listModel.model.headers" >
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="listModel.eintragLoeschen && listModel.eintragLoeschen.roles && listModel.eintragLoeschen.roles.indexOf(userRole) !== -1" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.moving="{ item,index }">
          <v-icon v-if="listModel.eintraegeNeuOrdnen && listModel.eintraegeNeuOrdnen.roles && listModel.eintraegeNeuOrdnen.roles.indexOf(userRole) !== -1" small @click="itemMove('up',item,index)" :disabled=" index<=0">
            mdi-arrow-up-bold
          </v-icon>
          <v-icon v-if="listModel.eintraegeNeuOrdnen && listModel.eintraegeNeuOrdnen.roles && listModel.eintraegeNeuOrdnen.roles.indexOf(userRole) !== -1" small @click="itemMove('down',item,index)"  :disabled="index >= tabelData.length-1">
            mdi-arrow-down-bold
          </v-icon>

        </template>

      <template v-slot:item.datei.name="{ item }">
<!--        <p>{{item}}</p>-->
        <a :href="backend+item.datei.url" target="_blank">{{ item.datei.name }}</a>
      </template>
      <template v-slot:item.url="{ item }">
        <a :href="item.url" target="_blank">{{ item.url }}</a>
      </template>

      <template v-slot:item.datei.createdAt="{ item }">
        <p>{{formatDate(item.datei.createdAt)}}</p>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <p>{{formatDate(item.createdAt)}}</p>
      </template>

    </v-data-table>


<!--    Neuer Eintrag Dialog-->
    <v-dialog v-if="listModel.neuerEintrag" v-model="neuerEintragShow" max-width="800px">
      <v-card >
        <v-card-title>
          <span class="headline">{{ listModel.neuerEintrag.titel }}</span>
        </v-card-title>

        <v-card-text>
          <template v-if="listModel.type === 'uploads'">
            <v-file-input v-model="newFiles"
                          :label="listModel.neuerEintrag.label"
                          :required="true"
                          multiple
                          truncate-length="15"
                          show-size
            ></v-file-input>
          </template>
          <template v-if="listModel.type === 'url'">

            <v-text-field v-model="newLink" prepend-inner-icon="mdi-link-variant-plus"></v-text-field>
          </template>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="listModel.neuerEintrag.initBtn.btnColor" @click="createEntry()" >{{ listModel.neuerEintrag.speichernBtnTitle }}</v-btn>

        </v-card-actions>

      </v-card>
    </v-dialog>


  </v-card>



</template>

<script >


import axios from "axios";

export default {
  name: "DBModel_CardTable",
  props: {
    listModel: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      name: "Informationen",
      backend: process.env.VUE_APP_STRAPI_API_URL,
      neuerEintragShow: false,
      newFiles: {},
      newLink: '',
      userId: null,
      tabelData:[],

      createEntry() {

        if (this.listModel.type === 'uploads') {
          if (this.newFiles && this.newFiles.length) {
            let formData = new FormData();
            for (let newFile of this.newFiles) {
              formData = new FormData();
              if (newFile) {
                formData.append("files", newFile, newFile.name);
                axios
                    .post(`${this.backend}/upload`, formData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: localStorage.getItem("token") || "",
                      },
                    })
                    .then((response) => {
                      axios
                          .post(`${this.backend}${this.listModel.endpoint}`, {
                            hochgeladen_von: null,
                            datei: response.data,
                            gewicht:0,
                          }, {
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: localStorage.getItem("token") || "",
                            },
                          })
                          .then(() => {
                            this.getListItems();
                            this.neuerEintragShow = false;
                            this.newFiles = {}
                          })
                    })
              }
            }
          }
        }

        if (this.listModel.type === 'url') {
          if (this.newLink && this.newLink.length) {

                      axios
                          .post(`${this.backend}${this.listModel.endpoint}`, {
                            url: this.newLink,
                            gewicht: 0
                          }, {
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: localStorage.getItem("token") || "",
                            },
                          })
                          .then(() => {
                            this.getListItems();
                            this.neuerEintragShow = false;
                            this.newLink = ''
                          })
              }
            }


      },
      deleteItem(item) {

          if (item) {
            axios
                .delete(`${this.backend}${this.listModel.endpoint}/${item.id}`, {}, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token") || "",
                  },
                })
                .then(() => {
                  this.getListItems();
                  this.neuerEintragShow = false;
                })


          }
      },
      itemMove(typ,item,index){
        if (typ && item) {
          let neuesGewicht = item.gewicht;

          if((index<=0 && typ === 'up') || (index >= this.tabelData.length-1 && typ === 'down')){
            return;
          }else {
            neuesGewicht = (typ === 'up'?this.tabelData[index-1].gewicht-1:this.tabelData[index+1].gewicht+1);
          }

          axios
              .put(`${this.backend}${this.listModel.endpoint}/${item.id}`, {
                gewicht: neuesGewicht,
              }, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: localStorage.getItem("token") || "",
                },
              })
              .then(() => {
                this.getListItems();
              })
        }
    },
      getListItems(){
        axios
            .get(`${this.backend}${this.listModel.endpoint}?_sort=gewicht:ASC`,  {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token") || "",
              },
            })
            .then((response) => {
              this.tabelData =  response.data
            })
      },
      formatDate(date){
        let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        return new Date(date).toLocaleDateString("de-DE",options)
      }
    }
  },
  created() {
    // if (localStorage.getItem("token")) this.userId = this.parseJwt(localStorage.getItem("token")).id;
    this.userRole = localStorage.getItem('role') ;

  },

  watch: {

  },
  computed: {

  },
  method: {


    },
  mounted() {
    this.getListItems()
  },
  }

</script>